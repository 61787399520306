<template>
  <div>
    <!-- 轮播 -->
    <div id="bannerId">
      <img
        style="width: 100%; height: 36.25rem"
        src="../assets/img/brandClient.jpg"
        alt=""
      />
    </div>
    <!-- 各个行业的共同选择 -->
    <div class="client_box" id="brandClient">
      <div class="industry_tit">
        <span class="extra_line">
          <span class="color_block"></span>
          各个行业的共同选择
        </span>
      </div>
      <!-- //客户logo区域 -->
      <div class="client_logo">
        <ul class="client_logo_ul">
          <li class="logo_item">
            <img src="../assets/img/clientlogo/huawei.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/2.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/3.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/4.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/5.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/6.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/7.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/8.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/9.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/10.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/11.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/12.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/13.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/14.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/15.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/16.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/17.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/18.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/19.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/20.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/21.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/22.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/23.png" alt="" />
          </li>
          <li class="logo_item">
            <img src="../assets/img/clientlogo/24.png" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <!-- 客户对赋盈的肯定 -->
    <div class="industry_box" id="ClientTalk">
      <div class="industry_tit">
        <span class="extra_line">
          <span class="color_block1"></span>
          客户说
        </span>
      </div>
      <div class="industry_item">
        <div class="industry_item_l">
          <div class="item_img_box">
            <img
              style="width: 5rem; height: 5rem"
              src="../assets/img/huawei.png"
              alt=""
            />
          </div>
          <div class="huawei_logo">华为 区域市场总监</div>
        </div>
        <div class="industry_item_r">
          <img
            style="width: 4.25rem; height: 3.6875rem"
            class="industry_item_r_triangle"
            src="../assets/img/triangle.png"
            alt=""
          />
          <div class="industry_item_r_outlineBox">
            赋盈数字化渠道系统，帮我们很好的渠道业务数字化，实现个性化的
            经销商订货方式。通过系统的数据中心，可以更好的针对不同的经销
            商提供更精准的服务，帮助他们实现更大的业务增长，还有更智能的
            数据预警，发现潜在的业务隐患，不漏单不流失客户。
          </div>
        </div>
      </div>
      <div class="industry_item">
        <div class="industry_item_r_two">
          <img
            style="width: 4.25rem; height: 3.6875rem"
            class="industry_item_r_triangle_two"
            src="../assets/img/triangle.png"
            alt=""
          />
          <div class="industry_item_r_outlineBox">
            我们是很早就和赋盈合作了，并且集团旗下多个品牌都已经展开合作
            了，总的来说对我们百草味业务增长的帮助和价值是显见的，特别是
            个性化的订货模式、智能化的数据预警以及经销商的拉新裂变等等这
            些能力，相信和赋盈会有更多的合作。
          </div>
        </div>
        <div class="industry_item_l_two">
          <div class="item_img_box">
            <img
              style="width: 6.25rem; height: 2.625rem"
              src="../assets/img/bcw.png"
              alt=""
            />
          </div>
          <div class="huawei_logo">百草味 渠道业务负责人</div>
        </div>
      </div>
      <div class="industry_item">
        <div class="industry_item_l">
          <div class="item_img_box">
            <img
              style="
                width: 4.75rem;
                height: 7.125rem;
                transform: translateY(0.8rem);
              "
              src="../assets/img/qianhuang.png"
              alt=""
            />
          </div>
          <div class="huawei_logo">钱皇股份 品牌负责人</div>
        </div>
        <div class="industry_item_r">
          <img
            style="width: 4.25rem; height: 3.6875rem"
            class="industry_item_r_triangle"
            src="../assets/img/triangle.png"
            alt=""
          />
          <div class="industry_item_r_outlineBox">
            和赋盈合作有一年多了，我们现在所有的店铺订货，全部通过赋盈数
            字化渠道智慧云系统，现在就不会再出现客户下错单，发错货的情况
            了，节省了很多不必要的费用，也大大提高了客户的购物体验，客户
            体验好了，从数据上就能看出客户的下单频次和金额都有提升。特别
            在上半年疫情期间，我们下面很多店铺都开不了门，我们通过系统帮
            助每个店铺开通了独立微店，每个店铺老板都通过线上的直播、分享
            、优惠券等社交化渠道销售，保证了线下店铺的持续性销售能力，也
            最大限度的帮助他们把生意做的更好。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    //创建时执行跳转锚点位置
    this.$nextTick(() => {
      this.getlocal();
    });
  },
  methods: {
    //从我本地找到id
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId);
      //如果对应id存在，就跳转
      if (selectId) {
        console.log(toElement, "toElement");
        toElement.scrollIntoView(true);
      }
    },
  },
  //离开页面进行对localStorage  id销毁，避免其他入口进来有锚点问题
  destroyed() {
    localStorage.setItem("toId", "");
  },
};
</script>

<style scoped>
.client_box {
  padding: 0 21.2rem;
  height: 48.375rem;
  background-color: #fafafa;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  box-sizing: border-box;
}
.industry_tit {
  font-size: 2.125rem;
  color: #333333;
  margin-bottom: 3.75rem;
}
.client_logo_ul {
  display: flex;
  flex-wrap: wrap;
}
.logo_item {
  text-align: left;
  list-style: none;
  width: 11.5625rem;
  height: 5.625rem;
  background-color: #fff;
  border-radius: 0.3125rem;
  margin-right: 1.125rem;
  margin-bottom: 2.5rem;
}
.logo_item:nth-child(6n) {
  margin-right: 0;
}
.logo_item:nth-child(19),
.logo_item:nth-child(20),
.logo_item:nth-child(21),
.logo_item:nth-child(22),
.logo_item:nth-child(23),
.logo_item:nth-child(24) {
  margin-bottom: 0;
}
.logo_item img {
  width: 100%;
}
.industry_box {
  padding: 6.25rem 22.5rem;
}
.industry_item {
  height: 22.25rem;
  /* background-color: red; */
  position: relative;
  margin-bottom: 6.25rem;
}
.industry_item:last-child {
  margin-bottom: 0;
}
.industry_item_l {
  position: absolute;
  left: 16.125rem;
  bottom: 0;
}
.industry_item_l_two {
  position: absolute;
  right: 16.125rem;
  bottom: 0;
}
.industry_item_l_two .item_img_box img {
  transform: translateY(3.125rem);
}
.item_img_box {
  width: 8.75rem;
  height: 8.75rem;
  background: #ffffff;
  box-shadow: 0px 0.25rem 0.4375rem 0px rgba(56, 56, 56, 0.26);
  border-radius: 50%;
  margin: 0 auto;
}
.item_img_box img {
  transform: translateY(1.5rem);
}
.huawei_logo {
  text-align: left;
  margin-top: 2.5rem;
  font-size: 1.25rem;
  color: #333;
}
.industry_item_r {
  padding: 2.18757rem 3.125rem;
  background-color: #4c61ee;
  width: 40rem;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0.625rem;
  box-sizing: border-box;
}
.industry_item_r_two {
  padding: 2.18757rem 3.125rem;
  background-color: #4c61ee;
  width: 40rem;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.625rem;
  box-sizing: border-box;
}
.industry_item_r_outlineBox {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 1.125rem;
  color: #fff;
  /* letter-spacing: 0.15em; */
  line-height: 2rem;
}
/* 三角 */
.industry_item_r_triangle {
  position: absolute;
  left: -1.875rem;
  bottom: -1.54rem;
}
.industry_item_r_triangle_two {
  position: absolute;
  right: -1.875rem;
  bottom: -1.54rem;
  transform: rotateY(180deg);
}
.extra_line {
  position: relative;
  display: inline-block;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #ccc;
}
.color_block {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 6.625rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
.color_block1 {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 2.1875rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
</style>