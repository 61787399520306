<template>
  <div>
    <!-- 轮播 -->
    <div id="bannerId">
      <img
        style="width: 100%; height: 36.25rem"
        src="../assets/img/about.jpg"
        alt=""
      />
    </div>
    <!-- 关于我们 -->
    <div class="about_we_box" id="aboutUs">
      <div class="about_we">
        <span class="extra_line">
          <span class="color_block33"></span>
          关于我们
        </span>
      </div>
      <div class="about_we_content">
        <p class="first_text">
          中国互联网技术的快速发展，对每个产业都产生了很大的变革和促进作用，特别在生产制造环节，互联网技术的开发和应用率尤为明显，自动化、数据化、智能化，包括最新的5G技术在我们国内也应用广泛。而在销售环节，更多的是像淘宝、京东、拼多多等第三方交易平台的兴起和近垄断，对于企业本身来讲，如何通过应用最新的互联网技术和产品，高效的管理好销售渠道并且给企业带来新的活力和业务增长，这个问题一直是企业主思考和迫切需求的。
        </p>
        <p>
          「赋盈软件」企业数字化渠道智慧云系统从这一点出发，深度调研市场，联合行业专家，共创行业代表性企业，在原来传统的渠道管理系统基础上变革创新，从管理渠道到业务赋能，从不知道怎么管理渠道到系统智能化预警，从单一的销售商机管理到多部门多业务流的深度协作，真正意义上实现企业品牌数字化，数字业务化，业务简单化。通过帮助企业建立自己的品牌订货商城，打造人、货、场、财的企业数据中心，整合CRM的精准营销和服务能力，BI的数据决策预警能力，AI人工智能的智慧帮手以及社交化的营销裂变和获客能力，最终形成和提高企业私域的品牌数字化渠道管理能力，实现企业的客户和业务快增长。
        </p>
      </div>
      <div class="about_we_pic">
        <img src="../assets/img/company.jpg" alt="" />
      </div>
    </div>
    <!-- 赋盈文化 -->
    <div class="history_box" id="culture">
      <div class="history_box_tit">
        <span class="extra_line">
          <span class="color_block33"></span>
          赋盈文化
        </span>
      </div>
      <div class="history_img">
        <div class="lump"></div>
        <div class="lump" @mouseover="changeActive">
          <div class="we_pic_box" style="z-index: 997">
            <img src="../assets/img/about/we_01.png" alt="" />
          </div>
          <div class="line" style="z-index: 997"></div>
          <div class="tit" style="z-index: 997">使命</div>
          <div
            style="z-index: 996"
            class="color_block"
            v-if="flag"
            @mouseout="removeActive"
          ></div>
          <transition>
            <!-- <div class="color_block" v-if="flag"></div> -->
            <div style="z-index: 997" class="sliding_block" v-if="flag">
              <p>让商业更智能</p>
              <p>让生意更简单</p>
            </div>
          </transition>
        </div>
        <div class="lump" @mouseover="changeActive1">
          <div style="z-index: 997" class="we_pic_box">
            <img src="../assets/img/about/we_02.png" alt="" />
          </div>
          <div class="line" style="z-index: 997"></div>
          <div class="tit" style="z-index: 997">愿景</div>
          <div
            style="z-index: 996"
            class="color_block"
            v-if="flag1"
            @mouseout="removeActive1"
          ></div>
          <transition>
            <div style="z-index: 997" class="sliding_block" v-if="flag1">
              <p>成为企业数字化渠道升级的最佳伙伴</p>
            </div>
          </transition>
        </div>
        <div class="lump" @mouseover="changeActive2">
          <div style="z-index: 997" class="we_pic_box">
            <img src="../assets/img/about/we_03.png" alt="" />
          </div>
          <div class="line" style="z-index: 997"></div>
          <div class="tit" style="z-index: 997">价值观</div>
          <div
            style="z-index: 996"
            class="color_block"
            v-if="flag2"
            @mouseout="removeActive2"
          ></div>
          <transition>
            <div style="z-index: 997" class="sliding_block" v-if="flag2">
              <p>客户满意是我们检验工作达标的基本要求</p>
              <p>客户获利是我们追求工作价值的导向目标</p>
            </div>
          </transition>
        </div>
        <div class="lump"></div>
      </div>
    </div>
    <!-- 关于赋盈 我们的优势 -->
    <div class="about_we_box" style="padding-top: 0">
      <div class="about_tit">
        <span class="extra_line">
          <span class="color_block33"></span>
          关于赋盈
        </span>
      </div>
      <div class="about_info_box">
        <div class="about_info_item">
           <div class="num">1<span style="font-size:40px">支</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>专注研究和服务企业</div>
            <div>数字化转型的团队</div>
          </div>
        </div>
        <div class="about_info_item">
            <div class="num">8<span style="font-size:40px">年</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>会员关系行业</div>
            <div>研究从业时间</div>
          </div>
        </div>
        <div class="about_info_item">
          <div class="num">16<span style="font-size:40px">年</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>互联网行业</div>
            <div>资深从业时间</div>
          </div>
        </div>
        <div class="about_info_item">
           <div class="num">16<span style="font-size:40px">年</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>企业营销战略</div>
            <div>团队管理经验</div>
          </div>
        </div>
        <div class="about_info_item">
           <div class="num">100,000<span style="font-size:40px">+</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>服务过的客户</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    //创建时执行跳转锚点位置
    this.$nextTick(() => {
      this.getlocal();
    });
  },
  data() {
    return {
      flag: false,
      flag1: false,
      flag2: false,
    };
  },
  methods: {
    //从我本地找到id
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId);
      //如果对应id存在，就跳转
      if (selectId) {
        console.log(toElement, "toElement");
        toElement.scrollIntoView(true);
      }
    },

    changeActive() {
      this.flag = true;
    },
    changeActive1() {
      this.flag1 = true;
    },
    changeActive2() {
      this.flag2 = true;
    },
    removeActive() {
      this.flag = false;
    },
    removeActive1() {
      this.flag1 = false;
    },
    removeActive2() {
      this.flag2 = false;
    },
  },
  //离开页面进行对localStorage  id销毁，避免其他入口进来有锚点问题
  destroyed() {
    localStorage.setItem("toId", "");
  },
};
</script>

<style scoped>
.about_we_box {
  padding: 6.25rem 22.5rem 10rem 22.5rem;
}
.about_we {
  font-size: 2.125rem;
  color: #333;
  margin-bottom: 3.75rem;
}
.about_we_content {
  font-size: 1.125rem;
  color: #333;
  text-align: left;
  line-height: 1.875rem;
  text-indent: 2em;
}
.first_text {
  margin-bottom: 1.875rem;
}
.about_we_pic {
  width: 45rem;
  height: 27.5rem;
  margin: 0 auto;
  margin-top: 3.75rem;
}
.about_we_pic img {
  width: 100%;
}
.history_box {
  margin-bottom: 12.5rem;
}
.history_box_tit {
  font-size: 2.125rem;
  margin-bottom: 3.75rem;
}
.history_img {
  display: flex;
  justify-content: space-between;
}
.history_img .lump {
  height: 31.25rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8.75rem 0;
  box-sizing: border-box;
  position: relative;
}
.color_block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(76, 97, 238, 0.8);
}
/* .history_img .lump:nth-child(2):hover {
  background-color: rgba(76, 97, 238, 0.8);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-blend-mode: multiply;
} */
.history_img .lump:nth-child(1) {
  background: #292b34;
}
.history_img .lump:nth-child(5) {
  background: #292b34;
}
.history_img .lump:nth-child(2) {
  background: url("../assets/img/about/bg_01.jpg") no-repeat center top;
  background-size: cover;
}
.history_img .lump:nth-child(3) {
  background: url("../assets/img/about/bg_03.jpg") center top no-repeat;
  background-size: cover;
}
.history_img .lump:nth-child(4) {
  background: url("../assets/img/about/bg_02.jpg") center top no-repeat;
  background-size: cover;
}
.we_pic_box {
  width: 3.75rem;
  height: 3.75rem;
  margin: 0 auto;
}
.we_pic_box img {
  width: 100%;
}
.lump .line {
  width: 1.875rem;
  height: 0.1875rem;
  background: #fff;
  margin: 0 auto;
}
.lump .tit {
  font-size: 1.5rem;
  color: #fff;
}
.sliding_block {
  font-size: 1rem;
  color: #ffffff;
  transition: all 0.5s;
}
.v-enter,
.v-leave-to {
  transform: translateY(9.375rem);
}

/* v-enter-active 【入场动画的时间段】 */
/* v-leave-active 【离场动画的时间段】 */
.v-enter-active,
.v-leave-active {
  transition: all 0.8s ease;
}
.about_we_box {
  padding: 6.25rem 22.5rem;
}
.about_tit {
  font-size: 2.125rem;
  color: #333333;
  margin-bottom: 7.5rem;
}
.about_info_box {
  display: flex;
  justify-content: space-between;
}
.about_info_item {
  text-align: left;
}
.about_info_item .num {
  font-size: 3.75rem;
  color: #4c61ee;
}
.about_info_item .transverse_line {
  margin-top: 3.75rem;
  margin-bottom: 1.875rem;
  width: 2.875rem;
  height: 0.375rem;
  background: #4c61ee;
  box-shadow: 0px 0.1875rem 0.25rem 0px rgba(0, 31, 238, 0.29);
  border-radius: 0.1875rem;
}
.extra_line {
  position: relative;
  display: inline-block;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #ccc;
}
.color_block33 {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 3rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
</style>