<template>
  <div>
    <!-- 轮播 -->
    <div id="bannerId">
      <img
        style="width: 100%; height: 36.25rem"
        src="../assets/img/channelTeam.jpg"
        alt=""
      />
    </div>
    <!-- 加入我们可获得十大支持 -->
    <div class="join_outBox" id="joinWe">
      <div class="join_tit">
        <span class="extra_line">
          <span class="color_block"></span>
          加入我们可获得十大支持
        </span>
      </div>
      <ul>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_01.png" alt="" />
          </div>
          <div class="each_tit">返利支持</div>
          <div class="each_text">携手赋盈，共掘万亿市场</div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_02.png" alt="" />
          </div>
          <div class="each_tit">产品支持</div>
          <div class="each_text">
            提供最完善的全行业营销解决方案，让合 伙人抓住客户资源
          </div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_03.png" alt="" />
          </div>
          <div class="each_tit">技术支持</div>
          <div class="each_text">
            <p>提供7×12小时</p>
            <p>在线技术服务</p>
            <p>保障高效</p>
            <p>快速解决</p>
          </div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_04.png" alt="" />
          </div>
          <div class="each_tit">培训支持</div>
          <div class="each_text">
            定期开展培训 帮助合伙人建立制度 打造竞争力
          </div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_05.png" alt="" />
          </div>
          <div class="each_tit">销售工具</div>
          <div class="each_text">提供详细的客户 管理系统，提高 工作效率</div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_06.png" alt="" />
          </div>
          <div class="each_tit">资源支持</div>
          <div class="each_text">
            总部资源全部给到 合伙人，无需承担 任何费用
          </div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_07.png" alt="" />
          </div>
          <div class="each_tit">推广支持</div>
          <div class="each_text">总部会不定期在 全国或当地进行 市场推广</div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_08.png" alt="" />
          </div>
          <div class="each_tit">物料支持</div>
          <div class="each_text">根据合伙人情况给予物料支持 利于开展业务</div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_09.png" alt="" />
          </div>
          <div class="each_tit">服务支持</div>
          <div class="each_text">提供7×12小时在线服务，让合伙人无 后顾之忧</div>
        </li>
        <li>
          <div class="each_pic_box">
            <img src="../assets/img/channel/channel_10.png" alt="" />
          </div>
          <div class="each_tit">售后支持</div>
          <div class="each_text">
            强大的研发团队 保障系统安全性 稳定性及可靠性
          </div>
        </li>
      </ul>
    </div>
    <!-- 关于赋盈 我们的优势 -->
    <div class="about_we_box" id="advantage">
      <div class="about_tit">
        <span class="extra_line">
          <span class="color_block1"></span>
          我们的优势
        </span>
      </div>
      <div class="about_info_box">
        <div class="about_info_item">
          <div class="num">1<span style="font-size:40px">支</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>专注研究和服务企业</div>
            <div>数字化转型的团队</div>
          </div>
        </div>
        <div class="about_info_item">
          <div class="num">8<span style="font-size:40px">年</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>会员关系行业</div>
            <div>研究从业时间</div>
          </div>
        </div>
        <div class="about_info_item">
          <div class="num">16<span style="font-size:40px">年</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>互联网行业</div>
            <div>资深从业时间</div>
          </div>
        </div>
        <div class="about_info_item">
          <div class="num">16<span style="font-size:40px">年</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>企业营销战略</div>
            <div>团队管理经验</div>
          </div>
        </div>
        <div class="about_info_item">
          <div class="num">100,000<span style="font-size:40px">+</span></div>
          <div class="transverse_line"></div>
          <div class="about_info_text">
            <div>服务过的客户</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 招商负责人 -->
    <div class="attract_box" id="contact">
      <div class="attract_tit">
        <span class="extra_line">
          <span class="color_block2"></span>
          招商负责人
        </span>
      </div>
      <div class="attract_name" style="text-align: center">陈先生</div>
      <div class="attract_contact">
        <div class="attract_contact_phone">
          电话：18067916510
          <span class="wx_text">(微信同号)</span>
        </div>
        <!-- <div class="attract_contact_wx">微信号: 15158853388</div> -->
      </div>
      <div class="add_qrcode">
        <img src="../assets/img/qrcode.png" alt="" />
        <p class="addwxtit">添加企业微信</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    //创建时执行跳转锚点位置
    this.$nextTick(() => {
      this.getlocal();
    });
  },
  methods: {
    //从我本地找到id
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId);
      //如果对应id存在，就跳转
      if (selectId) {
        console.log(toElement, "toElement");
        toElement.scrollIntoView(true);
      }
    },
  },
  //离开页面进行对localStorage  id销毁，避免其他入口进来有锚点问题
  destroyed() {
    localStorage.setItem("toId", "");
  },
};
</script>

<style scoped>
.wx_text {
  font-size: 1rem;
  transform: translateY(-0.625rem);
}
.join_outBox {
  padding: 6.25rem 20rem;
}
.join_tit {
  font-size: 2.125rem;
  color: #333333;
}
.each_pic_box {
  width: 7.5rem;
  height: 8.625rem;
  margin: 0 auto;
}
.each_pic_box img {
  width: 100%;
}
.each_tit {
  font-size: 1.5rem;
  color: #333333;
  margin-top: 1.8125rem;
  margin-bottom: 2.5rem;
}
.each_text {
  font-size: 1.125rem;
  color: #333333;
}
ul {
  display: flex;
  flex-wrap: wrap;
}
li {
  list-style: none;
  width: 9rem;
  margin-right: 8.3rem;
  margin-top: 3.75rem;
}
li:nth-child(5n) {
  margin-right: 0;
}
.about_we_box {
  padding: 6.25rem 22.5rem;
}
.about_tit {
  font-size: 2.125rem;
  color: #333333;
  margin-bottom: 7.5rem;
}
.about_info_box {
  display: flex;
  justify-content: space-between;
}
.about_info_item {
  text-align: left;
}
.about_info_item .num {
  font-size: 3.75rem;
  color: #4c61ee;
}
.about_info_item .transverse_line {
  margin-top: 3.75rem;
  margin-bottom: 1.875rem;
  width: 2.875rem;
  height: 0.375rem;
  background: #4c61ee;
  box-shadow: 0px 0.1875rem 0.25rem 0px rgba(0, 31, 238, 0.29);
  border-radius: 0.1875rem;
}
.attract_box {
  padding: 6.25rem 42.5rem;
}
.attract_tit {
  font-size: 2.125rem;
  color: #333333;
}
.attract_name {
  font-size: 1.75rem;
  color: #333333;
  text-align: left;
  margin-top: 3.75rem;
  margin-bottom: 2.5rem;
}
.attract_contact {
  display: flex;
  /* text-align: left; */
  text-align: center;
  margin: 0 auto;
}
.attract_contact_phone {
  font-size: 1.5rem;
  color: #333333;
  /* margin-right: 3.75rem; */
  text-align: center !important;
  margin: 0 auto;
}
.attract_contact_wx {
  font-size: 1.5rem;
  color: #333333;
}
.add_qrcode {
  width: 11.25rem;
  height: 11.25rem;
  margin: 0 auto;
  margin-top: 4.125rem;
}
.add_qrcode img {
  width: 100%;
}
.addwxtit {
  font-size: 1.125rem;
  color: #333;
  margin-top: 1.5rem;
}
.extra_line {
  position: relative;
  display: inline-block;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #ccc;
}
.color_block {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 8.125rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
.color_block1 {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 3.75rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
.color_block2 {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 3.75rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
</style>